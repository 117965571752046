var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.loadingComplete)?_c('div',[_c('div',{staticClass:"d-flex justify-content-center main-content"},[_c('div',{staticClass:"row d-flex justify-content-center",class:_vm.projectWidgets.length
          ? 'col-xl-10 col-md-11 col-sm-12 col-lg-10'
          : 'col-xl-9 col-md-11 col-sm-11 col-lg-9',staticStyle:{"min-height":"500px"}},[_c('div',{class:_vm.projectWidgets.length ? `col-md-8 ${_vm.paddingWebsite}` : `col-xxl-12 min-vh-100`},[_c('router-view')],1),(_vm.projectWidgets.length)?_c('div',{class:`col-md-4 ${_vm.paddingWebsite}`},_vm._l((_vm.computedWidgets),function(widget,index){return _c('div',{key:index},[(widget.system_widget_types_id == 1)?_c('TextAndMediaWidget',{attrs:{"widgetDetail":widget}}):(widget.system_widget_types_id == 2)?_c('CalendarWidget',{attrs:{"widgetDetail":widget}}):(widget.system_widget_types_id == 3)?_c('PollWidget',{attrs:{"widgetDetail":widget}}):(
              widget.system_widget_types_id == 4 && _vm.userRole !== 'observer'
            )?_c('RecentActivityWidget',{attrs:{"widgetDetail":widget}}):(widget.system_widget_types_id == 5)?_c('ExternalContentWidget',{attrs:{"widgetDetail":widget}}):_vm._e(),(
              widget.system_widget_types_id == 6 && _vm.userRole !== 'observer'
            )?_c('MemberOnlineWidget',{attrs:{"widgetDetail":widget}}):(widget.system_widget_types_id == 7)?_c('CommunityManagerWidget',{attrs:{"widgetDetail":widget}}):_vm._e()],1)}),0):_vm._e()])])]):_c('div',{staticClass:"d-flex justify-content-center main-content"},[_c('div',{staticClass:"row d-flex justify-content-center col-xl-10 col-md-11 col-sm-12 col-lg-10"},[_c('div',{class:`col-md-8 ${_vm.paddingWebsite}`},[_c('div',{staticClass:"card card-custom bgi-no-repeat card-stretch gutter-b w-100"},[_c('div',{staticClass:"card-body my-4 pd-12"},[_c('div',{staticClass:"d-flex justify-content-center"},[_c('b-spinner',{staticClass:"main-card-loader",attrs:{"label":"Loading..."}})],1)])])]),_c('div',{class:`col-md-4 ${_vm.paddingWebsite}`},_vm._l((3),function(index){return _c('div',{key:index},[_c('div',{staticClass:"card card-custom bgi-no-repeat card-stretch gutter-b"},[_c('div',{staticClass:"card-body my-4 pd-12"},[_c('div',{staticClass:"d-flex justify-content-center"},[_c('b-spinner',{staticClass:"card-loader",attrs:{"label":"Loading..."}})],1)])])])}),0)])])
}
var staticRenderFns = []

export { render, staticRenderFns }