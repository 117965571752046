<template>
  <div v-if="loadingComplete">
    <!--begin::Dashboard-->
    <!--begin::Dashboard Content-->
    <div class="d-flex justify-content-center main-content">
      <div
        class="row d-flex justify-content-center"
        :class="
          projectWidgets.length
            ? 'col-xl-10 col-md-11 col-sm-12 col-lg-10'
            : 'col-xl-9 col-md-11 col-sm-11 col-lg-9'
        "
        style="min-height:500px"
      >
        <!--begin::Main Section Content-->
        <div
          :class="
            projectWidgets.length ? `col-md-8 ${paddingWebsite}` : `col-xxl-12 min-vh-100`
          "
        >
          <router-view></router-view>
        </div>
        <!--end::Main Section Content-->

        <!--begin::Widget Section Content-->
        <div v-if="projectWidgets.length" :class="`col-md-4 ${paddingWebsite}`">
          <div v-for="(widget, index) in computedWidgets" :key="index">
            <TextAndMediaWidget
              :widgetDetail="widget"
              v-if="widget.system_widget_types_id == 1"
            ></TextAndMediaWidget>
            <CalendarWidget
              :widgetDetail="widget"
              v-else-if="widget.system_widget_types_id == 2"
            ></CalendarWidget>
            <PollWidget
              :widgetDetail="widget"
              v-else-if="widget.system_widget_types_id == 3"
            ></PollWidget>
            <RecentActivityWidget
              :widgetDetail="widget"
              v-else-if="
                widget.system_widget_types_id == 4 && userRole !== 'observer'
              "
            ></RecentActivityWidget>
            <ExternalContentWidget
              :widgetDetail="widget"
              v-else-if="widget.system_widget_types_id == 5"
            ></ExternalContentWidget>
            <MemberOnlineWidget
              :widgetDetail="widget"
              v-if="
                widget.system_widget_types_id == 6 && userRole !== 'observer'
              "
            ></MemberOnlineWidget>
            <CommunityManagerWidget
              :widgetDetail="widget"
              v-else-if="widget.system_widget_types_id == 7"
            ></CommunityManagerWidget>
          </div>
        </div>
        <!--end::Widget Section Content-->
      </div>
    </div>
    <!--end::Dashboard Content--->

    <!--end::Dashboard-->
  </div>
  <!--begin::Loading Spinner-->
  <div v-else class="d-flex justify-content-center main-content">
    <div
      class="row d-flex justify-content-center col-xl-10 col-md-11 col-sm-12 col-lg-10"
    >
      <div :class="`col-md-8 ${paddingWebsite}`">
        <div class="card card-custom bgi-no-repeat card-stretch gutter-b w-100">
          <div class="card-body my-4 pd-12">
            <div class="d-flex justify-content-center">
              <b-spinner
                class="main-card-loader"
                label="Loading..."
              ></b-spinner>
            </div>
          </div>
        </div>
      </div>
      <div :class="`col-md-4 ${paddingWebsite}`">
        <div v-for="index in 3" :key="index">
          <div class="card card-custom bgi-no-repeat card-stretch gutter-b">
            <div class="card-body my-4 pd-12">
              <div class="d-flex justify-content-center">
                <b-spinner class="card-loader" label="Loading..."></b-spinner>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!--end::Loading Spinner-->
</template>

<script>
import { mapGetters } from "vuex";

import TextAndMediaWidget from "@/view/content/widgets/TextAndMediaWidget.vue";
import CalendarWidget from "@/view/content/widgets/CalendarWidget.vue";
import PollWidget from "@/view/content/widgets/PollWidget.vue";
import RecentActivityWidget from "@/view/content/widgets/RecentActivityWidget.vue";
import CommunityManagerWidget from "@/view/content/widgets/CommunityManagerWidget.vue";
import ExternalContentWidget from "@/view/content/widgets/ExternalContentWidget.vue";
import MemberOnlineWidget from "@/view/content/widgets/MemberOnlineWidget.vue";

export default {
  name: "dashboard",
  components: {
    TextAndMediaWidget,
    CalendarWidget,
    PollWidget,
    RecentActivityWidget,
    CommunityManagerWidget,
    ExternalContentWidget,
    MemberOnlineWidget
  },
  data() {
    return {
      pageLoader: {
        componentsCompleted: 0,
        componentsRequired: 0
      }
    };
  },
  methods: {
    pageLink(page) {
      return "/page/" + page.id;
    }
  },
  computed: {
    ...mapGetters(["currentUser", "projectWidgets", "projectInfo", "pageList"]),
    loadingComplete: function() {
      if (
        this.pageLoader.componentsCompleted >=
        this.pageLoader.componentsRequired
      ) {
        return true;
      } else {
        return false;
      }
    },
    computedWidgets: function() {
      let widgetScope = this.$route.meta.widgets;
      let widgetsArray = [];
      const self = this;

      if (widgetScope == "global") {
        widgetsArray = this.projectWidgets.filter(function(widget) {
          return widget.activities.length == 0;
        });
      } else if (widgetScope == "activity_limited") {
        widgetsArray = this.projectWidgets.filter(function(widget) {
          return (
            widget.activities.filter(function(activity) {
              return activity.id == self.$route.params.id;
            }).length > 0 || widget.system_widget_types_id == 7
          );
        });
      } else if (widgetScope == "cm_only") {
        widgetsArray = this.projectWidgets.filter(function(widget) {
          return widget.system_widget_types_id == 7;
        });
      } else {
        widgetsArray = [];
      }

      return widgetsArray;
    },
    userId: function() {
      return this.currentUser.id;
    },
    userRole: function() {
      return this.currentUser.relation_to_project;
    },
    isMobile: function() {
      if (
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent
        )
      ) {
        return true;
      } else {
        return false;
      }
    },
    paddingWebsite: function() {
      return this.isMobile ? "" : "px-10";
    }
  }
};
</script>
