<template>
  <!--begin::External Content Widget-->
  <div class="card card-custom gutter-b">
    <div class="card-body">
      <h5 class="card-title">{{ title }}</h5>
      <div class="h_iframe">
        <iframe
          :src="url"
          frameborder="0"
          :height="height"
          width="auto"
          allow="autoplay; encrypted-media"
          allowfullscreen
        ></iframe>
      </div>
    </div>
  </div>
  <!--end::External Content Widget-->
</template>
<script>
export default {
  name: "external-content-widget",
  props: {
    widgetDetail: Object
  },
  computed: {
    widgetConfigs() {
      return this._.pick(this.widgetDetail, ["project_widget_configs"]);
    },
    title() {
      const index = this._.findKey(this.widgetConfigs.project_widget_configs, {
        name: "title"
      });
      const title = index
        ? this.widgetConfigs["project_widget_configs"][index].value
        : "";
      return title;
    },
    url() {
      const index = this._.findKey(this.widgetConfigs.project_widget_configs, {
        name: "url"
      });
      const url = index
        ? this.widgetConfigs["project_widget_configs"][index].value
        : "";
      return url;
    },
    height() {
      const index = this._.findKey(this.widgetConfigs.project_widget_configs, {
        name: "height"
      });
      const height = index
        ? this.widgetConfigs["project_widget_configs"][index].value
        : "";
      return height;
    }
  }
};
</script>
