<template>
  <div class="px-5">
    <div
      class="row d-flex mx-0"
      :class="
        classStyle == 'sm' ? 'align-items-start' : 'justify-content-center'
      "
    >
      <template v-for="(projectAsset, i) in mediaProjectAssets">
        <VideoThumb
          v-if="projectAsset.type == 1"
          class="my-4 mx-2"
          :classStyle="classStyle"
          :key="projectAsset.id"
          :projectAsset="projectAsset"
          :index="i"
          :removable="removable"
          @thumb_deleted="deleteThumb"
          @open_lightbox="openLightbox"
        >
        </VideoThumb>
        <ImageThumb
          v-if="projectAsset.type == 2"
          class="my-4 mx-2"
          :classStyle="classStyle"
          :key="i"
          :projectAsset="projectAsset"
          :index="i"
          :removable="removable"
          @thumb_deleted="deleteThumb"
          @open_lightbox="openLightbox"
        >
        </ImageThumb>
      </template>
      <template v-for="projectAsset in fileProjectAssets">
        <FileThumb
          :key="projectAsset.id"
          class="my-4 mx-2"
          :projectAsset="projectAsset"
          @thumb_deleted="deleteThumb"
        >
        </FileThumb>
      </template>
      <LightBox :media="media" :showLightBox="false"> </LightBox>
    </div>
    <LightBox ref="lightbox" :media="media" :showLightBox="false"> </LightBox>
  </div>
</template>

<script>
import VideoThumb from "@/view/components/media-thumbs/VideoThumb";
import ImageThumb from "@/view/components/media-thumbs/ImageThumb";
import FileThumb from "@/view/components/media-thumbs/FileThumb";
import LightBox from "vue-it-bigger";
import("vue-it-bigger/dist/vue-it-bigger.min.css"); // when using webpack

export default {
  name: "GenericThumbGallery",

  props: ["projectAssets", "classStyle", "removable"],

  components: {
    VideoThumb,
    ImageThumb,
    FileThumb,
    LightBox
  },

  data() {
    return {
      hovering: null,
      media: [],
      removing: []
    };
  },

  created() {
    this.projectAssets.forEach(asset => this.pushProjectAsset(asset));
  },

  methods: {
    deleteThumb: function(value) {
      this.removing.push(value.projectAssetId);
      this.$emit("remove_project_asset", value.projectAssetId);
    },
    openLightbox: function(value) {
      this.$refs.lightbox.showImage(value);
    },
    pushProjectAsset(asset) {
      if (asset.type == 1) {
        let lightboxAsset = {
          caption: asset.original_filename
        };
        if (asset.transcoded_at != null && asset.thumbnail_path != null) {
          let mediaSrc = this.isIOSdevice
            ? asset.originalSignedUrl
            : asset.signedUrl;
          let videoType = asset.original_extension.replace(".", "");
          let mediaType = this.isIOSdevice
            ? videoType == "mov"
              ? "video/quicktime"
              : "video/" + videoType
            : "video/webm";
          lightboxAsset.type = "video";
          (lightboxAsset.thumb = asset.signedThumbUrl),
            (lightboxAsset.sources = [
              {
                src: mediaSrc,
                type: mediaType
              }
            ]);
          if (this.isMobile) {
            let width =
              this.computedDeviceSizeVideoWidth > asset.width * 0.8
                ? asset.width
                : this.computedDeviceSizeVideoWidth * 0.8;
            let height =
              this.computedDeviceSizeVideoHeight > asset.height * 0.8
                ? asset.height
                : this.computedDeviceSizeVideoHeight * 0.8;

            lightboxAsset.width = width;
            lightboxAsset.height = height;
          } else {
            lightboxAsset.width = asset.width;
            lightboxAsset.height = asset.height;
          }
          lightboxAsset.autoplay = true;
        } else {
          lightboxAsset.type = "image";
          (lightboxAsset.thumb = "/media/misc/processing-video.png"),
            (lightboxAsset.src = "/media/misc/processing-video.png");
        }
        this.media.push(lightboxAsset);
      } else if (asset.type == 2) {
        let lightboxAsset = {
          caption: asset.original_filename
        };
        lightboxAsset.type = "image";
        if (asset.directory != "avatars") {
          (lightboxAsset.thumb = asset.signedUrl),
            (lightboxAsset.src = asset.signedUrl);
        } else {
          (lightboxAsset.thumb = asset.signedAvatarUrl),
            (lightboxAsset.src = asset.signedAvatarUrl);
        }
        this.media.push(lightboxAsset);
      }
    }
  },
  computed: {
    isMobile: function() {
      if (
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent
        )
      ) {
        return true;
      } else {
        return false;
      }
    },
    isIOSdevice: function() {
      if (/iPhone|iPad|iPod/i.test(navigator.userAgent)) {
        return true;
      } else {
        return false;
      }
    },
    computedDeviceSizeVideoWidth: function() {
      if (this.isMobile) {
        return window.innerWidth;
      } else {
        return false;
      }
    },
    computedDeviceSizeVideoHeight: function() {
      if (this.isMobile) {
        return window.innerHeight;
      } else {
        return false;
      }
    },
    mediaProjectAssets: function () {
      let self = this;
      return this.projectAssets.filter(function (obj) {
        return obj.type <= 2 && !self.removing.includes(obj.id);
      });
    },
    fileProjectAssets: function () {
      let self = this;
      return this.projectAssets.filter(function (obj) {
        return obj.type >= 3 && !self.removing.includes(obj.id);
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.cursor-move .align-items-start {
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
}
</style>