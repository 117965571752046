<template>
  <div
    v-on:mouseover="isHovering = true"
    v-on:mouseleave="isHovering = false"
    style="max-width:150px"
  >
    <a
      href="javascript:void(0)" 
      class="symbol symbol-45 symbol-light"
      @click="downloadAsset()"
    >
      <div class="symbol-label" style="width:150px; height:150px">
        <img src="/media/misc/download-asset.png" style="width:150px; height:150px; object-fit:cover">
      </div>
    </a>
    <div class="row d-flex justify-content-between mx-0">
      <div class="col-xl-9 px-0">
        <p class="font-size-sm">{{ projectAsset.original_filename }}</p>
      </div>
      <div class="col-xl-3 px-0 text-right">
        <a
          href="javascript:void(0)"
          @click="emitThumbDeletion()"
          v-if="isHovering"
        >
          <i class="fas fa-times-circle text-hover-danger"></i>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "FileThumb",

  props: [ 'projectAsset', 'index' ],

  data() {
    return {
      isHovering: false
    }
  },

  methods: {
    downloadAsset: function() {
      let url       = this.projectAsset.signedUrl;
      let fileName  = this.projectAsset.original_filename;

      var xhr = new XMLHttpRequest();
      xhr.open('GET', url, true);
      xhr.responseType = 'blob';

      xhr.onload = function () {
        var blob = xhr.response;
        var link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = fileName;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      };

      xhr.send();
    },
    emitThumbDeletion: function() {
      let payload = {
        projectAssetId: this.projectAsset.id,
        filename: this.projectAsset.original_filename
      }
      this.$emit("thumb_deleted",payload)
    }
  },
};
</script>
