<template>
  <div class="card card-custom gutter-b" v-if="loadingComplete == false">
    <div class="card-body my-4 pd-12">
      <div class="d-flex justify-content-center">
        <b-spinner class="card-loader" label="Loading..."></b-spinner>
      </div>
    </div>
  </div>
  <!--begin::Calendar Widget-->
  <div class="card card-custom gutter-b" v-else>
    <el-calendar v-model="value" id="calendar">
      <!-- Used here is 2.5  slot syntax, use for new projects 2.6  slot syntax-->
      <template slot="dateCell" slot-scope="{ date, data }">
        <!--Custom content-->
        <div>
          <div class="calendar-day">
            {{
              data.day
                .split("-")
                .slice(2)
                .join("-")
            }}
          </div>
          <div v-for="(item, key) in calendarWidgetInfo" :key="key">
            <div v-if="happenThisYear(item, data.day)">
              <div v-if="happenThisMonth(item, data.day)">
                <div v-if="happenThisDay(item, data.day)">
                  <el-tooltip
                    class="item"
                    effect="dark"
                    :content="item.title"
                    placement="right"
                  >
                    <a :href="activityLink(item)">
                      <div class="is-selected">
                        <el-button
                          type="info"
                          class="el-icon-star-on"
                          circle
                        ></el-button>
                      </div>
                    </a>
                  </el-tooltip>
                </div>
                <div v-else></div>
              </div>
              <div v-else></div>
            </div>
            <div v-else></div>
          </div>
        </div>
      </template>
    </el-calendar>
  </div>
  <!--end::Calendar Widget-->
</template>

<script>
import { mapGetters } from "vuex";
import moment from "moment";
import {
  POPULATE_CALENDAR_WIDGET_INFO,
  CLEAR_CALENDAR_WIDGET_ERRORS
} from "@/core/services/store/project/project_widgets.module.js";
import GeneralFunctionsHelper from "@/helpers/GeneralFunctions";
export default {
  name: "calendar-widget",
  props: {
    widgetDetail: Object
  },
  data() {
    return {
      pageLoader: {
        componentsCompleted: 0,
        componentsRequired: 1
      },
      value: new Date(),
      error: {
        active: false,
        message: ""
      }
    };
  },
  mounted() {
    this.fetchDateData();
  },
  methods: {
    clearErrors: function() {
      this.$store.dispatch(CLEAR_CALENDAR_WIDGET_ERRORS);
    },
    fetchDateData() {
      this.clearErrors();
      let payload = {
        widgetId: this.widgetDetail.id
      };
      this.$store
        .dispatch(POPULATE_CALENDAR_WIDGET_INFO, payload)
        .then(() => {
          this.pageLoader.componentsCompleted++;
        })
        .catch(() => {
          this.error.active = true;
          this.$toasted.global.error_toast({
            message: this.calendarWidgetError
          });
        });
    },
    happenThisYear(activity, date) {
      const timestring = activity.starts_on;
      const year = moment(timestring).year();
      const current_year = date.split("-").slice(0)[0];
      return year == current_year;
    },
    happenThisMonth(activity, date) {
      const timestring = activity.starts_on;
      const month = moment(timestring).format("MM");
      const current_month = date.split("-").slice(1)[0];
      return month == current_month;
    },
    happenThisDay(activity, date) {
      const timestring = activity.starts_on;
      const day = moment(timestring).format("DD");
      const current_day = date
        .split("-")
        .slice(2)
        .join("-");
      return day == current_day;
    },
    activityLink(activity) {
      if (activity.system_activity_type_id == 9) {
        return "/";
      } else {
        return GeneralFunctionsHelper.activityLink(activity);
      }
    }
  },
  computed: {
    ...mapGetters(["calendarWidgetInfo", "calendarWidgetError"]),

    loadingComplete: function() {
      if (
        this.pageLoader.componentsCompleted >=
        this.pageLoader.componentsRequired
      ) {
        return true;
      } else {
        return false;
      }
    }
  }
};
</script>
