import moment from "moment";
class GeneralFunctionsHelper {
  static calculateTimeDiff(datetime) {
    var diff = moment(datetime).fromNow(); //eg. 1 day, 2 hours
    return diff;
  }

  //Returns a font awesome icon for a specified activity type
  static activityIcon(activityType) {
    let icon = "";

    switch (activityType.name) {
      case "Blog":
        icon = "fas fa-pencil-alt";
        break;
      case "Diary":
        icon = "far fa-calendar-alt";
        break;
      case "Discussion":
        icon = "fas fa-comments";
        break;
      case "Survey":
        icon = "far fa-check-square";
        break;
      case "Picture Book":
        icon = "fas fa-camera";
        break;
      case "Ideastorm":
        icon = "fas fa-lightbulb";
        break;
      case "External":
        icon = "fas fa-external-link-alt";
        break;
      case "MarkUp":
        icon = "fas fa-map-pin";
        break;
      case "Poll":
        icon = "fas fa-chart-pie";
        break;
    }

    return icon;
  }

  static activityHeader(icon, activityDetails, type_text) {
    var title = activityDetails.title;
    return `<span class="mr-5"><i class="${icon}" v-b-tooltip.hover.right
            title="${type_text}"></i></span>${title}`;
  }

  static activityLink(activity) {
    let protocol = process.env.NODE_ENV == "local" ? "http://" : "https://";
    let subdomain = window.location.host.split(".")[0];
    return (
      protocol +
      subdomain +
      "." +
      process.env.VUE_APP_INPUT_URL_SUFFIX +
      "#/activity/" +
      activity.id
    );
  }

  static getSampleColor(number) {
    let sampleColor = [
      "white text-success",
      "primary",
      "success",
      "info",
      "warning",
      "danger",
      "dark"
    ];
    let index = parseInt(number) % sampleColor.length;
    let labelColor = sampleColor[index];
    return labelColor;
  }

  static getSecondsToTimeString(number, needHours = false) {
    var sec_num = parseInt(number, 10); // don't forget the second param
    var hours = Math.floor(sec_num / 3600);
    var minutes = Math.floor((sec_num - hours * 3600) / 60);
    var seconds = sec_num - hours * 3600 - minutes * 60;

    if (hours < 10) {
      hours = "0" + hours;
    }
    if (minutes < 10) {
      minutes = "0" + minutes;
    }
    if (seconds < 10) {
      seconds = "0" + seconds;
    }
    return needHours
      ? hours + ":" + minutes + ":" + seconds
      : minutes + ":" + seconds;
  }
}

export default GeneralFunctionsHelper;
