<template
  ><div class="card card-custom gutter-b" v-if="loadingComplete == false">
    <div class="card-body my-4 pd-12">
      <div class="d-flex justify-content-center">
        <b-spinner class="card-loader" label="Loading..."></b-spinner>
      </div>
    </div>
  </div>
  <!--begin::Community Manager Widget-->
  <div class="card card-custom p-9 gutter-b" v-else>
    <!--begin:Heading-->
    <h5 class="mb-5">{{ $t("WIDGET.COMMUNITY_MANAGER.TITLE") }}</h5>
    <!--end:Heading-->
    <p class="card-text" v-if="!communityManagerWidgetInfo.length">
      {{ $t("WIDGET.COMMUNITY_MANAGER.EMPTY_TEXT") }}
    </p>
    <div v-else>
      <template v-for="(item, i) in communityManagerWidgetInfo">
        <!--begin::Item -->
        <div class="mb-6" v-bind:key="i">
          <div
            class="d-flex align-items-start justify-content-between flex-grow-1"
          >
            <div
              class="d-flex flex-wrap align-items-center justify-content-start w-50px"
            >
              <UserThumbnail :user="item.user" size="50"></UserThumbnail>
            </div>
            <div
              class="d-flex flex-wrap align-items-center justify-content-start w-90 ml-3"
            >
              <div class="d-flex flex-column align-items-cente mr-2 w-100">
                <router-link
                  :to="{ path: `/community/${item.id}` }"
                  class="text-dark-75 font-weight-bold text-hover-primary font-size-sm"
                >
                  <AnonymisedUsername :user="item.user"></AnonymisedUsername>
                </router-link>
              </div>
              <span
                class="text-primary mr-2"
                style="white-space: pre-line !important"
              >
                {{ item.cm_profile }}
              </span>
            </div>
          </div>
        </div>
        <!--end::Item -->
      </template>
    </div>
  </div>
  <!--end::Community Manager Widget-->
</template>

<script>
import { mapGetters } from "vuex";
import {
  POPULATE_COMMUNITY_MANAGER_WIDGET_INFO,
  CLEAR_COMMUNITY_MANAGER_WIDGET_ERRORS
} from "@/core/services/store/project/project_widgets.module.js";

import UserThumbnail from "@/modules/together-helpers/components/generic-displays/GenericUserThumbnail";

export default {
  name: "community-manager-widget",
  props: {
    widgetDetail: Object
  },
  data() {
    return {
      pageLoader: {
        componentsCompleted: 0,
        componentsRequired: 1
      },
      error: {
        active: false,
        message: ""
      }
    };
  },
  components: {
    UserThumbnail
  },
  mounted() {
    this.fetchCommunityManagerData();
  },
  methods: {
    clearErrors: function() {
      this.$store.dispatch(CLEAR_COMMUNITY_MANAGER_WIDGET_ERRORS);
    },
    fetchCommunityManagerData() {
      this.clearErrors();
      let payload = {
        widgetId: this.widgetDetail.id
      };
      this.$store
        .dispatch(POPULATE_COMMUNITY_MANAGER_WIDGET_INFO, payload)
        .then(() => {
          this.pageLoader.componentsCompleted++;
        })
        .catch(() => {
          this.error.active = true;
          this.$toasted.global.error_toast({
            message: this.communityManagerWidgetError
          });
        });
    }
  },
  computed: {
    ...mapGetters([
      "communityManagerWidgetInfo",
      "communityManagerWidgetError"
    ]),

    loadingComplete: function() {
      if (
        this.pageLoader.componentsCompleted >=
        this.pageLoader.componentsRequired
      ) {
        return true;
      } else {
        return false;
      }
    }
  }
};
</script>
