<template>
  <div>
    <b-form-group
      :id="id + '-group'"
      :label="computedLabel"
      :label-sr-only="!displayLabel"
      :label-for="id"
      v-slot="{ ariaDescribedby }"
    >
      <b-form-checkbox-group
        v-model="model.$model"
        :state="validateState()"
        :aria-describedby="ariaDescribedby"
        size="lg"
        :stacked="showInline ? false : true"
        :disabled="disabled || submissionStates.submitting === true"
      >
        <b-form-checkbox
          v-for="option in options"
          :value="option.value"
          v-bind:key="option.text"
          class="mb-1"
          :checked="option.value"
        >
          <p 
            class="pt-1 mb-2"
            v-if="option.tooltip != undefined"
            v-b-tooltip.hover.right
            :title="option.tooltip"
          >
            {{ option.text }}
          </p>
          <p 
            v-else
            class="pt-1 mb-2" 
          >
            {{ option.text }}
          </p>
        </b-form-checkbox>
      </b-form-checkbox-group>
    </b-form-group>
    <div class="custom-validation-failure mb-7" v-if="model.$error">
      {{ error }}
    </div>
  </div>
</template>

<script>
export default {
  name: "InputGenericCheckboxGroup",
  
  props: {
    id: { type: String, required: true },
    disabled: { type: Boolean, required: false },
    showInline: { type: Boolean, required: false },
    error: { type: String, required: false },
    label: { type: String, required: false },
    options: { type: Array, required: true },
    model: {type: Object, required: true },
    submissionStates: { type: Object, required: true },
    stateValidation: { type: Boolean, required: false, default: true }
  },

  methods: {
    validateState() {
      if (this.stateValidation) {
        const { $dirty, $error } = this.model;
        return $dirty ? !$error : null;        
      }
    }
  },

  computed: {
    computedLabel: function() {
      if (this.label !== undefined) {
        if (this.model.$params.hasOwnProperty("required")) {
          return this.label + "*";
        } else {
          return this.label;
        }
      } else {
        return "";
      }
    },

    displayLabel: function() {
      if (this.label != undefined) {
        if (this.label.length > 0) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    }
  }
};
</script>
