<template>
  <div class="card card-custom gutter-b" v-if="loadingComplete == false">
    <div class="card-body my-4 pd-12">
      <div class="d-flex justify-content-center">
        <b-spinner class="card-loader" label="Loading..."></b-spinner>
      </div>
    </div>
  </div>
  <!--begin::Member Online Widget-->
  <div class="card card-custom gutter-b" v-else>
    <div class="card-body">
      <h5 class="card-title">{{ $t("WIDGET.MEMBER_ONLINE.TITLE") }}</h5>
      <p class="card-text" v-if="!memberOnlineWidgetInfo.length">
        {{ $t("WIDGET.MEMBER_ONLINE.EMPTY_TEXT") }}
      </p>
      <div class="d-flex flex-wrap align-items-center" v-else>
        <div v-for="(user, i) in memberOnlineWidgetInfo" v-bind:key="i">
          <router-link :to="{ path: `/community/${user.projectProfile.id}` }">
            <el-tooltip
              class="item"
              effect="dark"
              :content="user.display_name"
              placement="right"
            >
              <UserThumbnail :user="user" size="45"></UserThumbnail>
            </el-tooltip>
          </router-link>
        </div>
      </div>
    </div>
  </div>
  <!--end::Member Online Widget-->
</template>

<script>
import { mapGetters } from "vuex";
import _ from "lodash";
import {
  POPULATE_MEMBER_ONLINE_WIDGET_INFO,
  CLEAR_MEMBER_ONLINE_WIDGET_ERRORS
} from "@/core/services/store/project/project_widgets.module.js";
import UserThumbnail from "@/modules/together-helpers/components/generic-displays/GenericUserThumbnail";
export default {
  name: "member-online-widget",
  props: {
    widgetDetail: Object
  },
  data() {
    return {
      pageLoader: {
        componentsCompleted: 0,
        componentsRequired: 1
      },
      error: {
        active: false,
        message: ""
      }
    };
  },
  components: {
    UserThumbnail
  },
  mounted() {
    this.fetchMemberOnlineData();
  },
  methods: {
    randomAvatar() {
      return "/media/users/300_" + _.random(0, 25) + ".jpg";
    },
    clearErrors: function() {
      this.$store.dispatch(CLEAR_MEMBER_ONLINE_WIDGET_ERRORS);
    },
    fetchMemberOnlineData() {
      this.clearErrors();
      let payload = {
        widgetId: this.widgetDetail.id
      };
      this.$store
        .dispatch(POPULATE_MEMBER_ONLINE_WIDGET_INFO, payload)
        .then(() => {
          this.pageLoader.componentsCompleted++;
        })
        .catch(() => {
          this.error.active = true;
          this.$toasted.global.error_toast({
            message: this.memberOnlineWidgetError
          });
        });
    }
  },
  computed: {
    ...mapGetters(["memberOnlineWidgetInfo", "memberOnlineWidgetError"]),
    loadingComplete: function() {
      if (
        this.pageLoader.componentsCompleted >=
        this.pageLoader.componentsRequired
      ) {
        return true;
      } else {
        return false;
      }
    }
  }
};
</script>
