<template>
  <div class="card card-custom gutter-b" v-if="loadingComplete == false">
    <div class="card-body my-4 pd-12">
      <div class="d-flex justify-content-center">
        <b-spinner class="card-loader" label="Loading..."></b-spinner>
      </div>
    </div>
  </div>
  <!--begin::Poll Widget-->
  <div class="card card-custom gutter-b" v-else>
    <div class="card-body" v-if="!showResult">
      <p
        class="card-title font-size-h5 font-weight-bold"
        v-html="pollInfo.question.question"
      ></p>
      <InputGenericThumbGallery
        :projectAssets="pollInfo.activityStimuli"
        v-if="pollInfo.activityStimuli.length"
        class="w-100 mb-7"
        :classStyle="symbol-150"
      >
      </InputGenericThumbGallery>

      <!--begin::Multiple Choice Question-->
      <InputGenericCheckboxGroup
        v-if="answerType == 2"
        :id="`question-${pollInfo.question.id}-answer`"
        :model="$v.poll_answer"
        :options="computedChoices"
        :submissionStates="submissionStates"
        :error="computedPollInput.error"
      >
      </InputGenericCheckboxGroup>
      <!--end::Multiple Choice Question-->

      <!--begin::Single Choice Question-->
      <InputGenericRadioOption
        v-if="answerType == 3"
        :id="`question-${pollInfo.question.id}-answer`"
        :model="$v.poll_answer"
        :options="computedChoices"
        :submissionStates="submissionStates"
        :error="computedPollInput.error"
        :displayLabel="false"
      >
      </InputGenericRadioOption>
      <!--end::Single Choice Question-->
      <div class="mt-10 d-flex flex-row-reverse">
        <button class="btn btn-primary" @click="submitAnswer">
          {{ $t("WIDGET.POLL.BUTTON") }}
        </button>
      </div>
    </div>
    <div class="card-body" v-else>
      <div v-if="!pollAnswered">
        <h5 class="mb-5">{{ $t("WIDGET.POLL.TITLE") }}</h5>
        {{ $t("WIDGET.POLL.NO_RESULT") }}
      </div>
      <InputGenericChart v-else :pollData="pollSummary"></InputGenericChart>
    </div>
  </div>
  <!--end::Poll Widget-->
</template>

<script>
import { mapGetters } from "vuex";
import { validationMixin } from "vuelidate";
import {
  POPULATE_POLL_WIDGET_INFO,
  CLEAR_POLL_WIDGET_INFO,
} from "@/core/services/store/project/project_widgets.module.js";
import InputGenericChart from "@/view/components/chart/GenericChart.vue";
import InputGenericThumbGallery from "@/modules/together-helpers/components/generic-inputs/generic_integrated_dropzone/GenericThumbGallery";
import InputGenericRadioOption from "@/modules/together-helpers/components/generic-inputs/GenericRadioOption";
import InputGenericCheckboxGroup from "@/modules/together-helpers/components/generic-inputs/GenericCheckboxGroup";
import {
  CREATE_POLL_RESPONSES,
  POPULATE_ACTIVITY_POLL_SUMMARY,
} from "@/core/services/store/activity/activity_poll_responses.module";
import { required } from "vuelidate/lib/validators";

export default {
  mixins: [validationMixin],
  name: "poll-widget",
  props: {
    widgetDetail: Object,
  },
  data() {
    return {
      poll_answer: [],

      submissionStates: {
        submitting: false,
      },

      pageLoader: {
        componentsCompleted: 0,
        componentsRequired: 1,
      },

      error: {
        active: false,
        message: "",
      },
    };
  },
  mounted() {
    this.fetchPollData();
  },

  components: {
    InputGenericChart,
    InputGenericThumbGallery,
    InputGenericRadioOption,
    InputGenericCheckboxGroup,
  },

  validations: {
    poll_answer: {
      required,
    },
  },

  methods: {
    clearPollWidgetState: function () {
      let payload = {
        widgetId: this.widgetDetail.id,
      };
      this.$store.dispatch(CLEAR_POLL_WIDGET_INFO, payload);
    },
    fetchPollData() {
      this.clearPollWidgetState(this.widgetDetail.id);
      let payload = {
        widgetId: this.widgetDetail.id,
      };
      this.$store.dispatch(POPULATE_POLL_WIDGET_INFO, payload).then(() => {
        if (this.showResult) {
          this.fetchPollSummary();
        } else {
          this.pageLoader.componentsCompleted++;
        }
      });
    },
    fetchPollSummary() {
      let payload = {
        activityId: this.activityId,
      };
      this.$store.dispatch(POPULATE_ACTIVITY_POLL_SUMMARY, payload).then(() => {
        this.pageLoader.componentsCompleted++;
      });
    },
    preparePayload() {
      let answer = [];
      answer[this.pollInfo.question.id] = this.poll_answer;
      let elem = {
        activity_id: this.activityId, // required
        project_profile_id: this.currentUser.project_profiles.id,
        user_id: this.currentUser.id,
        system_activity_type_id: 9,
        response: answer,
      };
      return elem;
    },
    submitAnswer() {
      this.$v.poll_answer.$touch();

      if (this.$v.poll_answer.$anyError) {
        return;
      }
      let data = this.preparePayload();
      let payload = {
        activityId: this.activityId, // required
        data: data,
      };
      this.submissionStates.submitting = true;
      this.$store
        .dispatch(CREATE_POLL_RESPONSES, payload)
        .then(() => {
          this.pageLoader.componentsRequired++;
          this.fetchPollData();
          this.$toasted.global.success_toast({
            message: this.$t("RESPONSE_FORMS.ADD_SUCCESS.9"),
          });
        })
        .catch((msg) => {
          this.$log.debug(msg);
          this.$toasted.global.error_toast({
            message: msg.errors,
          });
        });
    },
  },

  computed: {
    ...mapGetters([
      "currentUser",
      "pollWidgetInfo",
      "pollWidgetError",
      "activityPollSummary",
    ]),
    activityId() {
      const index = this._.findKey(this.widgetDetail.project_widget_configs, {
        name: "poll",
      });
      const activityId = index
        ? this.widgetDetail["project_widget_configs"][index].value
        : "";
      return activityId;
    },
    pollInfo() {
      return this._.find(this.pollWidgetInfo, {
        widgetId: this.widgetDetail.id,
      });
    },
    pollSummary() {
      // return {};
      return this._.find(this.activityPollSummary, {
        activityId: this.activityId,
      });
    },
    showResult() {
      return this.pollInfo.responseStatus == "eligible" ? false : true;
    },
    pollAnswered() {
      return this.showResult ? this._.isEmpty(this.pollSummary, true) ? false: true : true;
    },
    answerType() {
      let Id = this.pollInfo.question.answer_type.id;
      return Id == "2" ? 2 : Id == "3" ? 3 : false;
    },
    media() {
      if (this.pollInfo.activityStimuli.length > 0) {
        return this.pollInfo.activityStimuli[0].signedUrl;
      } else {
        return [];
      }
    },
    computedChoices() {
      let options = [];
      this.pollInfo.choices.forEach((choice) => {
        let option = choice;
        option.text = choice.choice;
        option.value = choice.id;
        options.push(option);
      });
      return options;
    },

    computedPollInput() {
      let label = this.pollInfo.question.question;
      let error = this.$t("FORMS.GENERIC_ERRORS.REQUIRED");
      return { label: label, error: error };
    },
    loadingComplete: function () {
      if (
        this.pageLoader.componentsCompleted >=
        this.pageLoader.componentsRequired
      ) {
        return true;
      } else {
        return false;
      }
    },
  },
};
</script>
