<template>
  <!--begin::Text and Media Widget-->
  <div class="card card-custom gutter-b">
    <div class="card-body">
      <h5 class="card-title">{{ title }}</h5>
      <p class="card-text" v-html="content"></p>
      <InputGenericThumbGallery
        :projectAssets="widgetAssets"
        :removable="false"
      >
      </InputGenericThumbGallery>
    </div>
  </div>
  <!--end::Text and Media Widget-->
</template>

<script>
import InputGenericThumbGallery from "@/view/components/form-inputs/GenericThumbGallery.vue";

export default {
  name: "text-and-media-widget",
  components: {InputGenericThumbGallery},
  props: {
    widgetDetail: Object
  },
  computed: {
    widgetConfigs() {
      return this._.pick(this.widgetDetail, ["project_widget_configs"]);
    },
    widgetAssets() {
      return this._.pick(this.widgetDetail, ["project_assets"]).project_assets;
    },
    title() {
      const index = this._.findKey(this.widgetConfigs.project_widget_configs, {
        name: "title"
      });
      const title = index
        ? this.widgetConfigs["project_widget_configs"][index].value
        : "";
      return title;
    },
    content() {
      const index = this._.findKey(this.widgetConfigs.project_widget_configs, {
        name: "content"
      });
      const content = index
        ? this.widgetConfigs["project_widget_configs"][index].value
        : "";
      return content;
    },
    media() {
      return this.widgetAssets[0].signedUrl;
    }
  }
};
</script>
