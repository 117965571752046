<template>
  <div class="card-body p-0">
    <p
      class="card-title font-size-h5 font-weight-bold"
      v-html="pollData.question"
    ></p>
    <div ref="chartdiv" class="chart"></div>
  </div>
</template>

<script>
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import am4themes_dataviz from "@amcharts/amcharts4/themes/dataviz";

am4core.useTheme(am4themes_dataviz);
am4core.useTheme(am4themes_animated);

export default {
  name: "InputGenericChart",
  props: {
    pollData: {
      type: Object,
      required: true
    }
  },

  mounted() {
    let chart = am4core.create(this.$refs.chartdiv, am4charts.PieChart);
    // Add data
    chart.data = this.pollData.data;

    // Add and configure Series
    var pieSeries = chart.series.push(new am4charts.PieSeries());
    pieSeries.dataFields.value = "count";
    pieSeries.dataFields.category = "choice";
    pieSeries.slices.template.propertyFields.fill = "color";
    pieSeries.slices.template.stroke = am4core.color("#fff");
    pieSeries.slices.template.strokeWidth = 2;
    pieSeries.slices.template.strokeOpacity = 1;

    // This creates initial animation
    pieSeries.hiddenState.properties.opacity = 1;
    pieSeries.hiddenState.properties.endAngle = -90;
    pieSeries.hiddenState.properties.startAngle = -90;

    var colorSet = new am4core.ColorSet();

    colorSet.list = ["#00297a", "#2acdff", "#e40049", "#b6003a", "#1BC5BD"].map(function(color) {
      return new am4core.color(color);
    });

    pieSeries.colors = colorSet;

    // Disable labels
    pieSeries.labels.template.disabled = true;
    // pieSeries.colors.list = [
    //   am4core.color("#845EC2"),
    //   am4core.color("#D65DB1"),
    //   am4core.color("#FF6F91"),
    //   am4core.color("#FF9671"),
    //   am4core.color("#FFC75F"),
    //   am4core.color("#F9F871")
    // ];
    this.chart = chart;
  },

  beforeDestroy() {
    if (this.chart) {
      this.chart.dispose();
    }
  }
};
</script>
<style scoped>
.chart {
  width: 100%;
  height: 300px;
}
</style>
