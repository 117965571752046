<template>
  <div class="card card-custom gutter-b" v-if="loadingComplete == false">
    <div class="card-body my-4 pd-12">
      <div class="d-flex justify-content-center">
        <b-spinner class="card-loader" label="Loading..."></b-spinner>
      </div>
    </div>
  </div>
  <!--begin::Recent Activity Widget-->
  <div class="card card-custom p-9 gutter-b" v-else>
    <!--begin:Heading-->
    <h5 class="mb-5">{{ $t("WIDGET.RECENT_ACTIVITY.TITLE") }}</h5>
    <!--end:Heading-->
    <p class="card-text" v-if="!recentActivityWidgetInfo.length">
      {{ $t("WIDGET.RECENT_ACTIVITY.EMPTY_TEXT") }}
    </p>
    <div v-else>
      <template v-for="(item, i) in recentActivityWidgetInfo">
        <!--begin::Item -->
        <div class="mb-6" v-bind:key="i">
          <div
            class="d-flex align-items-center justify-content-between flex-grow-1"
          >
            <div
              class="d-flex flex-wrap align-items-center justify-content-start w-50px"
            >
              <div class="symbol symbol-50 mr-3">
                <img
                  :src="item.user.signedAvatar"
                  alt=""
                  class="symbol-label"
                />
              </div>
            </div>
            <div
              class="d-flex flex-wrap align-items-center justify-content-start w-55 ml-3"
            >
              <div class="d-flex flex-column align-items-cente mr-2">
                <router-link
                  :to="{ path: `/community/${item.project_profile.id}` }"
                  class="text-dark-75 font-weight-bold text-hover-primary font-size-sm"
                >
                  <AnonymisedUsername :user="item.user"></AnonymisedUsername>
                </router-link>
              </div>
              <span class="text-muted font-size-sm mr-2">
                <!-- desc should descript the action like posts, comments, replies -->
                has posted
              </span>

              <a :href="activityLink(item)">
                <span class="text-primary font-weight-bold font-size-sm mr-2">
                  {{ item.activity.title }}
                </span>
              </a>
            </div>
            <div
              class="d-flex flex-wrap align-items-center justify-content-end w-35"
            >
              <span
                class="label label-lg label-inline font-weight-bold py-4 label-light-primary"
              >
                <GenericDatetimeDisplay
                  :long="true"
                  :date="item.updated_at"
                  :lastUpdated="true"
                  :relative="false"
                >
                </GenericDatetimeDisplay>
              </span>
            </div>
          </div>
        </div>
        <!--end::Item -->
      </template>
    </div>
  </div>
  <!--end::Recent Activity Widget-->
</template>

<script>
import { mapGetters } from "vuex";
import GenericDatetimeDisplay from "@/modules/together-helpers/components/generic-displays/GenericDatetimeDisplay";
import {
  POPULATE_RECENT_ACTIVITY_WIDGET_INFO,
  CLEAR_RECENT_ACTIVITY_WIDGET_ERRORS
} from "@/core/services/store/project/project_widgets.module.js";
import GeneralFunctionsHelper from "@/helpers/GeneralFunctions";
export default {
  name: "recent-activity-widget",
  props: {
    widgetDetail: Object
  },
  data() {
    return {
      pageLoader: {
        componentsCompleted: 0,
        componentsRequired: 1
      },
      error: {
        active: false,
        message: ""
      }
    };
  },
  components: {
    GenericDatetimeDisplay
  },
  mounted() {
    this.fetchRecentActivityData();
  },
  methods: {
    clearErrors: function() {
      this.$store.dispatch(CLEAR_RECENT_ACTIVITY_WIDGET_ERRORS);
    },
    activityLink(activity) {
      return GeneralFunctionsHelper.activityLink(activity);
    },
    fetchRecentActivityData() {
      this.clearErrors();
      let payload = {
        widgetId: this.widgetDetail.id
      };
      this.$store
        .dispatch(POPULATE_RECENT_ACTIVITY_WIDGET_INFO, payload)
        .then(() => {
          this.pageLoader.componentsCompleted++;
        })
        .catch(() => {
          this.error.active = true;
          this.$toasted.global.error_toast({
            message: this.recentActivityWidgetError
          });
        });
    }
  },
  computed: {
    ...mapGetters(["recentActivityWidgetInfo", "recentActivityWidgetError"]),

    loadingComplete: function() {
      if (
        this.pageLoader.componentsCompleted >=
        this.pageLoader.componentsRequired
      ) {
        return true;
      } else {
        return false;
      }
    }
  }
};
</script>
