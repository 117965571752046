<template>
  <div
    v-on:mouseover="isHovering = true"
    v-on:mouseleave="isHovering = false"
    :style="computedStyle"
  >
    <a
      href="javascript:void(0)"
      :class="classStyle ? 'symbol symbol-45 symbol-light' : ''"
      @click="openLightbox"
    >
      <div
        :style="computedStyle"
        :class="!classStyle ? 'el-image' : 'symbol-label'"
      >
        <img
          :class="!classStyle ? 'el-image__inner el-image__preview' : ''"
          :src="calculatedThumb"
          :style="`${computedStyle} object-fit:cover`"
        />
      </div>
    </a>
    <div class="row d-flex justify-content-between mx-0" v-if="removable">
      <div class="col-xl-9 px-0">
        <p class="font-size-sm">{{ projectAsset.original_filename }}</p>
      </div>
      <div class="col-xl-3 px-0 text-right">
        <a
          href="javascript:void(0)"
          @click="emitThumbDeletion()"
          v-if="isHovering"
        >
          <i class="fas fa-times-circle text-hover-danger"></i>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "VideoThumb",

  props: ["projectAsset", "index", "classStyle", "removable"],

  data() {
    return {
      isHovering: false
    };
  },

  methods: {
    openLightbox: function() {
      this.$emit("open_lightbox", this.index);
    },
    emitThumbDeletion: function() {
      let payload = {
        index: this.index,
        projectAssetId: this.projectAsset.id,
        filename: this.projectAsset.original_filename
      };
      this.$emit("thumb_deleted", payload);
    }
  },

  computed: {
    calculatedThumb: function() {
      if (this.projectAsset.thumbnail_path != null) {
        return this.projectAsset.signedThumbUrl;
      } else {
        return "/media/misc/processing-video.png";
      }
    },
    computedStyle: function() {
      if (this.classStyle == "sm") {
        return "width:150px; height:150px;";
      } else {
        return "";
      }
    }
  }
};
</script>
